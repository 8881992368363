html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  /* Inherit box-sizing to make it easier to change the property for components that leverage other behavior; see http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
  box-sizing: inherit;
}

body {
  background: black;
  overflow-x: hidden; /* Prevents horizontal scrolling */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

// Function to generate random box-shadow values with percentages instead of fixed pixels
@function multiple-box-shadow($num) {
  $shadows: ();

  @for $i from 1 through $num {
    // Generate random percentages instead of fixed pixels
    $x-percent: random(100);
    $y-percent: random(100);

    // Use CSS calc() with custom properties that will be set by JavaScript
    $shadows: append(
      $shadows,
      calc(var(--page-width) * #{$x-percent} / 100)
        calc(var(--page-height) * #{$y-percent} / 100)
        white,
      comma
    );
  }

  @return $shadows;
}

// Generate star layers using percentages
$shadows-small: multiple-box-shadow(700);
$shadows-medium: multiple-box-shadow(200);
$shadows-big: multiple-box-shadow(100);

// Set default values that will be overridden by JavaScript
:root {
  --page-width: 100vw;
  --page-height: 100vh;
}

#stars {
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: $shadows-small;
  animation: animStar 50s linear infinite;

  &:after {
    content: " ";
    position: absolute;
    top: var(--page-height); // Use the variable instead of fixed 2000px
    width: 1px;
    height: 1px;
    background: transparent;
    box-shadow: $shadows-small;
  }
}

#stars2 {
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: $shadows-medium;
  animation: animStar 100s linear infinite;

  &:after {
    content: " ";
    position: absolute;
    top: var(--page-height); // Use the variable
    width: 2px;
    height: 2px;
    background: transparent;
    box-shadow: $shadows-medium;
  }
}

#stars3 {
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: $shadows-big;
  animation: animStar 150s linear infinite;

  &:after {
    content: " ";
    position: absolute;
    top: var(--page-height); // Use the variable
    width: 3px;
    height: 3px;
    background: transparent;
    box-shadow: $shadows-big;
  }
}

// Update animation to use the variable height
@keyframes animStar {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(calc(-1 * var(--page-height)));
  }
}

hr {
  border: 0;
  @include divider();
  margin-top: $spacing--shared-v;
  margin-bottom: $spacing--shared-v;
}

ul,
ol {
  margin-top: 0;
  padding-left: $spacing--shared-h;

  &:not(:last-child) {
    margin-bottom: $spacing--shared-v;
  }
}

ul {
  list-style: disc;
}

ol {
  list-style: decimal;
}

li {
  &:not(:last-child) {
    margin-bottom: $spacing--shared-v / 2;
  }
}

li > ul,
li > ol {
  margin-top: $spacing--shared-v / 2;
  margin-bottom: 0;
}

dl {
  margin-top: 0;
  margin-bottom: $spacing--shared-v;
}

dt {
  @include font-weight(strong);
}

dd {
  margin-left: $spacing--shared-h;
  margin-bottom: $spacing--shared-v;
}

img {
  border-radius: $img-radius;
}

img,
svg,
video {
  display: block;
  height: auto; /* Make sure images are scaled correctly. */
  max-width: 100%; /* Adhere to container width. */
}

figure {
  margin: $spacing--figure-v $spacing--figure-h; /* Extra wide images within figure tags don't overflow the content area. */
}

figcaption {
  padding: $spacing--figcaption-v $spacing--figcaption-h;
}

// tables
table {
  @include font-size(table);
  border-collapse: collapse;
  margin-bottom: $spacing--shared-v;
  width: 100%;
}

tr {
  border-bottom: 1px solid color-border(divider);
}

th {
  @include font-weight(strong);
  text-align: left;
}

th,
td {
  padding: $spacing--table-cell-v $spacing--table-cell-h;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
}

.invert-color {
  hr {
    @include divider(false, inverse);
  }

  tr {
    border-bottom: 1px solid color-border(divider-inverse);
  }
}

.social-links {
  margin-top: 20px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center; /* Centers the items horizontally */
  gap: 15px; /* Adjust spacing between icons */
}

.icon-link {
  position: relative;
  display: inline-block;
  transition: transform 0.2s ease-in-out;
}

.icon-link img {
  width: 30px;
  cursor: pointer;
}

.icon-link:hover {
  transform: scale(1.3); /* Slight zoom on hover */
}

.tooltip {
  position: absolute;
  bottom: 120%; /* Position above the icon */
  left: 50%;
  transform: translateX(-50%);
  background-color: black;
  color: white;
  font-size: 12px;
  padding: 5px 8px;
  border-radius: 4px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease-in-out;
}

.icon-link:hover .tooltip {
  opacity: 1;
  visibility: visible;
}

.main-text {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}

.list-container {
  text-align: left;
  display: inline-block;
}

.list-container ul {
  list-style: none; /* Removes bullet points */
  padding: 0; /* Removes default padding */
}
